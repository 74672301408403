import * as React from 'react';

function getImage(product){
    let startIndex = 0;
    if(product.attributes?.images?.data && product.attributes?.images?.data.length > 0){
        if(product.attributes.default_image && product.attributes.default_image > 0){
            startIndex = product.attributes?.images?.data.findIndex((e) => e.id === product.attributes.default_image )
        }
        const img = product.attributes?.images?.data[startIndex]
        return `${process.env.NEXT_PUBLIC_UPLOAD_STRAPI_URL}${img.attributes.url}?format=webp&resize=108x164&embed&quality=100`   //500x500
    }
    return `/assets/showcase/images/no-image.svg`
}

function formatPrice(n, x, s, c, num){
    const re = '\\d(?=(\\d{' + (x || 3) + '})+' + (n > 0 ? '\\D' : '$') + ')';
    return (c ? num.replace('.', c) : num).replace(new RegExp(re, 'g'), '$&' + (s || ','));
}

function getCount(product){
    let count = 0
    if(product.attributes.stores && product.attributes.stores.length > 0){
        // product.attributes.stores.forEach((s, n) => {
        //     price += s.price
        // })
        count = product.attributes.stores.reduce((a, c) => a + c.count, 0)
    }
    return count
}

function getPrice(product, goodId = 0){
    let price = 0
    if(product.attributes.price && product.attributes.price > 0){
        price = product.attributes.price
    }
    if(product.attributes?.offers && product.attributes?.offers.length > 0){
        product.attributes.offers.forEach((o, n) => {
            if(o.price > price){
                price = o.price
            }
        })
    }
    if(goodId > 0){
        if(product.attributes.goods.length>0){
            const good = product.attributes.goods.find((e) => e.id === parseInt(goodId))
            good.setGood.map((item, n)=> {
                price += getPrice(item.item.data)
            })
        }
    }
    if(product.attributes.productDiscount && product.attributes.productDiscount > 0){
        price = product.attributes.productDiscount
    }
    return price
}

function getPriceFull(product, goodId = 0){
    let price = {
        base: 0,
        goods: 0,
        offers: 0,
        discount: 0
    }
    if(product.attributes.price && product.attributes.price > 0){
        price.base = product.attributes.price
    }
    if(product.attributes?.offers && product.attributes?.offers.length > 0){
        product.attributes.offers.forEach((o, n) => {
            if(o.price > price.offers){
                price.offers = o.price
            }
        })
    }
    if(goodId > 0){
        if(product.attributes.goods.length>0){
            const good = product.attributes.goods.find((e) => e.id === parseInt(goodId))
            good.setGood.map((item, n)=> {
                price.goods += getPrice(item.item.data)
            })
        }
    }
    if(product.attributes.productDiscount && product.attributes.productDiscount > 0){
        price.discount = product.attributes.productDiscount
    }
    return price
}

function getPriceDiscount(product){
    if(product.attributes.price && product.attributes.productDiscount && product.attributes.price > product.attributes.productDiscount > 0){
        return Math.round(((product.attributes.price-product.attributes.productDiscount)/product.attributes.price)*100)
    }else{
        return null
    }
}

function getPriceType(product){
    let priceType = null
    let price = 0
    if(product.attributes?.offers && product.attributes?.offers.length > 0){
        product.attributes.offers.forEach((o, n) => {
            if(o.price > price){
                price = o.price
                priceType = o.type.data.id
            }
        })
    }
    return priceType
}

function getCountType(product){
    let countType = null
    let count = 0
    if(product.attributes.stores && product.attributes.stores.length > 0){
        product.attributes.stores.forEach((c, n) => {
            if(c.count > count){
                count = c.count
                countType = c.store.data.id
            }
        })
    }
    return countType
}

export { getCount, getPrice, formatPrice, getImage, getPriceType, getCountType, getPriceFull, getPriceDiscount }