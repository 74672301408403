import axios from 'axios';
import format from 'date-fns/format';

export async function sendMailOrderEdit(order, statusName, shop, email){
    const subject = `Статус заказа изменен в магазине ${shop.shopPrefix}`
    const text = `Статус заказа изменен ${order.attributes.phone} - ${order.attributes.email}`
    const html = `<!DOCTYPE html>
    <html>
    <head>
    <style>
    table, th, td {
    border: 1px solid black;
    border-collapse: collapse;
    }
    th {
        padding-left: 3px;
        padding-right: 3px;
    }
    </style>
    </head>
    <body>
    <h3>Заказ № ${order.id} от ${format(new Date(), "dd.MM.yyyy")} </h3>
    <p><b>Статус:</b> ${statusName}</p>
    <p><b>Имя покупателя:</b> ${order.attributes.clientName}</p>
    <p><b>Телефон:</b> <a href="tel:${order.attributes.phone.replace(/\D/g, "")}">${order.attributes.phone}</a></p>
    <h3>Товары:</h3>
    <table style="">
        <tr>
            <th>Название</th>
            <th>Цена, ₽</th>
            <th>Кол-во</th>
            <th>Сумма, ₽</th>
        </tr>
    ${order.attributes.items.map((item) => 
        `<tr>
            <td><b>${item.name}</b></td> 
            <td style="text-align: center">${item.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")}</td>
            <td style="text-align: center">${item.quantity}</td>
            <td style="text-align: center"><b>${(item.price * item.quantity).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")}</b></td>
        </tr>`
    ).join('')}
    </table>
    <p><b>Сумма: ${parseInt(order.attributes.total).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")} ₽</b></p>
    </body>
    </html>`
    await axios.post(`/api/sendmail`, { email, subject, text, html});
}

export async function sendMailOrder(data, cart, total, orderId, shop, email){
    const subject = `Новый заказ в интернет-магазине ${shop.shopPrefix}`
    const text = `Новый заказ в интернет-магазине ${data.phone} - ${data.email}`
    const html = `<!DOCTYPE html>
    <html>
    <head>
    <style>
    table, th, td {
    border: 1px solid black;
    border-collapse: collapse;
    }
    th {
        padding-left: 3px;
        padding-right: 3px;
    }
    </style>
    </head>
    <body>
    <h3>Заказ № ${orderId} от ${format(new Date(), "dd.MM.yyyy")} </h3>
    <p><b>Имя покупателя:</b> ${data.name}</p>
    <p><b>Телефон:</b> <a href="tel:${data.phone.replace(/\D/g, "")}">${data.phone}</a></p>
    <h3>Товары:</h3>
    <table style="">
        <tr>
            <th>Название</th>
            <th>Цена, ₽</th>
            <th>Кол-во</th>
            <th>Сумма, ₽</th>
        </tr>
    ${cart.cartItems.map((item) => 
        `<tr>
            <td><b><a href='https://${shop.shopPrefix}.ecommy.ru${item.slug}'}>${item.name}</a></b></td> 
            <td style="text-align: center">${item.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")}</td>
            <td style="text-align: center">${item.qty}</td>
            <td style="text-align: center"><b>${(item.price * item.qty).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")}</b></td>
        </tr>`
    ).join('')}
    </table>
    <p><b>Сумма: ${parseInt(total).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")} ₽</b></p>
    </body>
    </html>`
    await axios.post(`/api/sendmail`, { email, subject, text, html});
}

export function getProductUrl(prod){
    let url = ''
    if(prod.attributes.cats?.data?.length>0){
        const cat = prod.attributes.cats?.data[0]
        if(cat.attributes.parent?.data){
            url += `/${cat.attributes.parent?.data.attributes.slug}`
        }
        url += `/${cat.attributes.slug}`
    }
    url += `/product/${prod.attributes.slug}`
    return url
}

export function getProductImage(product, imgSize){
    if(product?.attributes?.images?.data && product.attributes?.images?.data.length > 0){
        let startIndex = 0, altIndex = product.attributes?.images?.data.length > 1 ? 1 : 0;
        if(product.attributes.default_image && product.attributes.default_image > 0){
            startIndex = product.attributes?.images?.data.findIndex((e) => e.id === product.attributes.default_image)
            altIndex = startIndex === 0 && product.attributes?.images?.data.length > 1 ? 1 : 0;
        }
        return {
            default: `${process.env.NEXT_PUBLIC_UPLOAD_STRAPI_URL}${product.attributes?.images?.data[startIndex].attributes.url}?format=webp&resize=${imgSize.width}x${imgSize.height}&embed&quality=${imgSize.quality}`,
            alternative: `${process.env.NEXT_PUBLIC_UPLOAD_STRAPI_URL}${product.attributes?.images?.data[altIndex].attributes.url}?format=webp&resize=${imgSize.width}x${imgSize.height}&embed&quality=${imgSize.quality}`
        }
    }
    return {default: `/assets/showcase/images/no-image.svg`}
}

// Поиск ключей фильтра в GET запросе
export  function getFilterParam(query, key, def = null){
    let index = -1
    let id = def
    if(query.slug && query.slug.length > 0){
        index = query.slug.findIndex((el)=> el === key)
        if (index > -1) {
            id = query.slug.slice(index+1, index+2)[0]
            query.slug.splice(index, 2)
        }
    }
    return id
}

export function getUrlFilter(asPath, key, key2){
    let url = asPath.split("/")
    const index = url.findIndex((el)=> el === key)
    if (index > -1) {
        url.splice(index, 2)
    }
    if(key2){
        const index2 = url.findIndex((el)=> el === key2)
        if (index2 > -1) {
            url.splice(index2, 2)
        }
    }
    return url
}

export function stringGen(len) {
    var text = "";
    
    var charset = "abcdefghijklmnopqrstuvwxyz0123456789";
    
    for (var i = 0; i < len; i++)
      text += charset.charAt(Math.floor(Math.random() * charset.length));
    
    return text;
}