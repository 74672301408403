import * as React from 'react';
import { useDispatch, useSelector } from "react-redux";
import clsx from 'clsx';
import { styled } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { addToCart, updateCart } from '@/store/cartSlice';
import {getPrice, getCount, getImage, getPriceType, getCountType, getPriceFull} from './libPoduct'
import {StyledButton} from "@/components/showcase/_controls";
import NextLink from "next/link";
import IconButton from "@mui/material/IconButton";
import {getThemeColors} from "@/components/showcase/_helpers";

const BootstrapTooltip = styled(({ className, colorScheme, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme, colorScheme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: colorScheme ? getThemeColors(colorScheme) : '#758BD2',
    },
    [`& .${tooltipClasses.tooltip}`]: {
        color: colorScheme && colorScheme === 8 ? '#000000' : '#FFFFFF',
        borderRadius: '10px',
        fontSize: '0.875rem',
        fontWeight: '400',
        backgroundColor: colorScheme ? getThemeColors(colorScheme) : '#758BD2',
        padding: '12px 30px'
    },
  }));

function AddCartProduct({slug, product, goodId = 0, goodsCart = null, isCart, one, colorScheme}){
    const dispatch = useDispatch();
    const cart = useSelector(({ cart }) => cart);
    const inCart = cart.cartItems.find((e) => (e.id === product.id) && (e.goodId === goodId))
    const [qty, setQty] = React.useState(1);
    const count = isCart ? product.count : product.attributes.count
    const mobileScreen = useMediaQuery((theme) => theme.breakpoints.down('md'));

    function getQtyInCart(){
        if(inCart){
            return inCart.qty
        }
        return qty
    }

    if(!isCart && count <= 0 && getPrice(product) === 0 ){
       return <></>
    }

    function add(q){
        if(inCart){
            let newCart = cart.cartItems.map((p) => {
                if (p.goodsCart && (p.id === inCart.id) && (p.goodId === inCart.goodId)) {
                    const goodsCartNew = p.goodsCart.map((g)=> {
                        return {
                            ...g,
                            qty: q ? q : getQtyInCart()
                        }
                    })
                    return { ...p, goodsCart: goodsCartNew, qty: q ? q : getQtyInCart() };
                }else if((p.id === inCart.id) && (p.goodId === inCart.goodId)){
                    return { ...p, qty: q ? q : getQtyInCart() };
                }
                return p
              })
              dispatch(updateCart(newCart))
        }else{
            dispatch(
                addToCart({
                    id: product.id,
                    count: isCart ? product.count : product.attributes.count,
                    name: isCart ? product.name : product.attributes.name,
                    slug: isCart ? product.slug : (slug ? slug : `/product/${product.attributes.slug}`),
                    price: isCart ? product.price : getPrice(product),
                    priceB: isCart ? product.priceB : getPriceFull(product).base,
                    image: isCart ? product.image : getImage(product),
                    priceId: isCart ? product.priceId : getPriceType(product),
                    storeId: isCart ? product.storeId : getCountType(product),
                    goodsCart,
                    goodId,
                    qty
                })
            );
        }
    }

    function plus(){
        if((((count - getQtyInCart()) > 0) || (count === 0))){
            const q = (getQtyInCart()+1) > 0 ? getQtyInCart()+1 : 1
            changeQty(q)
        }
    }

    function minus(){
        const q = (getQtyInCart()-1) > 0 ? getQtyInCart()-1 : 1
        if((getQtyInCart()-1) === 0){
            if(inCart && !isCart){
                del()
            }
        }else{
            changeQty(q)
        }
        
    }

    function del(){
        const delEl = cart.cartItems.findIndex((e) => (e.id === product.id) && (e.goodId === goodId))
        const newItems = cart.cartItems.filter((e, i) => i !== delEl)
        dispatch(
            updateCart(newItems)
        )
        setQty(1)
    }

    function changeQty(q){
        setQty(parseInt(q))
        if(inCart){
            add(parseInt(q))
        }
    }

    const ButtonIconh40 = ({ className, children, onClick }) => {
        return(
            <IconButton onClick={onClick} className={clsx(!isCart ? 'h-10 w-10' : 'h-7.5 w-7.5','flex flex-col space-y-1', 'rounded-lg cursor-pointer row-center', className)}>
                { children }
            </IconButton>
        )
    }
    const ButtonIconh40f = ({ className, children }) => {
        return(
            <StyledButton onClick={add} className={clsx('h-10 w-full tablet:w-36 text-center', className)} text={ children } colorScheme={colorScheme}/>
        )
    }

    if(one){
        return(
            <div className='flex flex-row items-center space-x-6'>
                <BootstrapTooltip disableHoverListener={count === 0} title={`Осталось: ${count - getQtyInCart()} шт.`} placement="bottom-start" colorScheme={colorScheme}>
                    <div className='flex flex-row items-center space-x-1'>
                        <ButtonIconh40 className='flex items-center justify-center bg-white-6 hover:bg-gray-2' onClick={minus}>
                            <div className='text-2xl mb-1'>-</div>
                            {/*<IconFlaticon className={clsx('text-black', isCart ? 'text-xs' : 'm-auto text-xs')} name='rr-minus' />*/}
                        </ButtonIconh40>
                        <div className='w-8 text-center'>{getQtyInCart()}</div>
                        <ButtonIconh40 className='flex items-center justify-center bg-white-6 hover:bg-gray-2' onClick={plus}>
                            <div className='text-xl'>+</div>
                            {/*<IconFlaticon className={clsx('text-black', isCart ? 'text-xs' : 'm-auto text-xs')} name='rr-plus' />*/}
                        </ButtonIconh40>
                    </div>
                </BootstrapTooltip>
                {inCart ? (
                    <StyledButton
                        href={'/cart'}
                        component={NextLink}
                        className={'h-10 w-full tablet:w-36 laptop:w-48 text-center text-sm tablet:text-base'}
                        text={mobileScreen ? 'В корзине' : 'Перейти в корзину'}
                        colorScheme={colorScheme}
                        inverse={'true'}
                    />
                ):(
                    <ButtonIconh40f className={clsx(one && 'laptop:w-48', 'h-9 tablet:h-10 text-sm tablet:text-base')} >
                        В корзину
                    </ButtonIconh40f>
                )}
            </div>
        )
    }

    if(inCart){
        return(
            <div className='flex flex-row items-center w-full h-9 tablet:h-10 space-x-4'>
                {!isCart && (
                    <StyledButton
                        href={'/cart'}
                        component={NextLink}
                        className={'h-9 tablet:h-10 w-full tablet:w-36 text-center text-sm tablet:text-base'}
                        text={'В корзине'}
                        colorScheme={colorScheme}
                        inverse={'true'}
                    />
                )}
                {(!mobileScreen || isCart) && (
                    <BootstrapTooltip disableHoverListener={count === 0} title={`Осталось: ${count - getQtyInCart()} шт.`} placement="bottom-start" colorScheme={colorScheme}>
                        <div className='flex flex-row items-center space-x-1'>
                            <ButtonIconh40 className={clsx(isCart && 'w-8 h-8 ', 'flex items-center justify-center bg-white-6 hover:bg-gray-2')} onClick={minus}>
                                <div className='text-2xl mb-1'>-</div>
                                {/*<IconFlaticon className={clsx('text-black', isCart ? 'text-xs' : 'm-auto text-xs')} name='rr-minus' />*/}
                            </ButtonIconh40>
                            <div className='w-8 text-center'>{getQtyInCart()}</div>
                            <ButtonIconh40 className={clsx(isCart && 'w-8 h-8 ', 'flex items-center justify-center bg-white-6 hover:bg-gray-2')} onClick={plus}>
                                <div className='text-xl'>+</div>
                                {/*<IconFlaticon className={clsx('text-black', isCart ? 'text-xs' : 'm-auto text-xs')} name='rr-plus' />*/}
                            </ButtonIconh40>
                        </div>
                    </BootstrapTooltip>
                )}
            </div>
        )
    }

    return(
        <>
            <ButtonIconh40f className='h-9 tablet:h-10 text-sm tablet:text-base'>
                В корзину
            </ButtonIconh40f>
        </>
    )
}

export default AddCartProduct